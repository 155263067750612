import React from "react";

export default function Contact() {
  return (
    <>
      <h1>
        amogus
      </h1>
      <h2>
        Contact me for a Consultation! Please send a 2 minute audio recording of
        a piece, and include one scale.
      </h2>
      <h3>Email: Cmpbuss@comcast.net</h3>
    </>
  );
}
